import Matter from 'matter-js'
import * as PIXI from 'pixi.js'
import router from './router';
export default class Ball {
  private pixiBall: PIXI.SimplePlane;
  private pixiIcon?: PIXI.Sprite = undefined;
  private pixiText: PIXI.Text;
  private matterBall: Matter.Composite;
  private path: string;
  private count = Math.floor(Math.random() * 60) + 60;
  private iconX = 0;
  private iconY = 0;
  private textX: number;
  private textY: number;
  private r = 15 + this.getRandomInt(10);
  private length = 0;

  constructor(title: string, path: string, stage: PIXI.Container, world: Matter.World, texture: PIXI.Texture, x: number, y: number, length: number, iconTexture?: PIXI.Texture){
    this.length = length;
    if(this.length>7)this.length = 7;
    this.r = 10 + this.length * 2 + this.getRandomInt(10);
    if (window.innerWidth < 1100) this.r *= 0.5;
    this.path = path;
    this.pixiBall = new PIXI.SimplePlane(texture, 3, 3);
    const colors = [0xE060D9];
    // this.pixiBall.tint = colors[Math.floor(Math.random()*colors.length)];
    if(iconTexture){
      this.pixiIcon = new PIXI.Sprite(iconTexture);
      this.pixiIcon.anchor.set(.5, .5);
      this.pixiIcon.width = this.r * 2.8;
      this.pixiIcon.height = this.r * 2.8;
      this.pixiIcon.alpha = 0;
      this.iconX = this.pixiIcon.scale.x;
      this.iconY = this.pixiIcon.scale.y;
    }
    this.pixiBall.alpha = 0;
    const wrapCnt = 5;
    if(title.length>wrapCnt){
      title = title.slice(0, wrapCnt) + ' ' + title.slice(wrapCnt, title.length);
    }
    this.pixiText = new PIXI.Text(title);
    if (title.length > wrapCnt) {
      this.pixiText.style.wordWrap = true;
      this.pixiText.style.wordWrapWidth = this.r * 4;
    }
    this.pixiText.alpha = 0;
    this.pixiText.style.fontSize = 80;
    this.pixiText.style.fill = 0xff007f;
    this.pixiText.style.align = 'center';
    this.pixiText.width = this.r * 3.2;
    this.pixiText.scale.y = this.pixiText.scale.x;
    this.textX = this.pixiText.scale.x;
    this.textY = this.pixiText.scale.y;
    this.pixiBall.interactive = true;
    this.pixiBall.on('click', this.onClick.bind(this));
    this.pixiBall.on('tap', this.onClick.bind(this));
    // const x = 200 + this.getRandomInt(400);
    // const y = 100 + this.getRandomInt(200);
    const particleOptions = {
      friction: 0.03,
      frictionAir: 0.02,
      frictionStatic: 0.1,
      render: { visible: true }
    };
    this.matterBall = Matter.Composites.softBody(x, y, 3, 3, 0, 0, true, this.r, particleOptions, {
      damping: 0.001,
      stiffness: 0.04
    });
    stage.addChild(this.pixiBall);
    if (iconTexture)stage.addChild(this.pixiIcon!);
    stage.addChild(this.pixiText);
    Matter.World.add(world, this.matterBall);
  }

  public update(){
    const buf = (this.pixiBall as PIXI.SimplePlane).geometry as any;
    const uvs = buf.buffers[0].data;
    let j = 0;
    for (const body of this.matterBall.bodies) {
      let offsetX = 0;
      let offsetY = 0;
      offsetX = (body.position.x - this.matterBall.bodies[4].position.x) / this.count
      offsetY = (body.position.y - this.matterBall.bodies[4].position.y) / this.count
      uvs[j] = body.position.x + offsetX;
      uvs[j + 1] = body.position.y + offsetY;
      j += 2;
    }
    buf.buffers[0].update();
    // this.pixiText.scale.x = 1 / this.count;
    // this.pixiText.scale.y = 1 / this.count;
    this.pixiBall.alpha = 1 / this.count;
    this.pixiText.alpha = 1 / this.count;
    if(this.pixiIcon){
      this.pixiIcon.alpha = 1 / this.count;
      this.pixiIcon.scale.x = this.iconX / this.count;
      this.pixiIcon.scale.y = this.iconY / this.count;
      this.pixiIcon.position.x = this.matterBall.bodies[4].position.x;
      this.pixiIcon.position.y = this.matterBall.bodies[4].position.y - this.r / 2;
      this.pixiText.position.x = this.matterBall.bodies[4].position.x - this.pixiText.width / 2;
      this.pixiText.position.y = this.matterBall.bodies[4].position.y - this.pixiText.height / 2 + this.r;
    }else{
      this.pixiText.position.x = this.matterBall.bodies[4].position.x - this.pixiText.width / 2;
      this.pixiText.position.y = this.matterBall.bodies[4].position.y - this.pixiText.height / 2;
    }
    this.pixiText.scale.x = this.textX / this.count;
    this.pixiText.scale.y = this.textY / this.count;
    this.count--;
    if(this.count<=0)this.count=1;
  }

  public applyForce(w: number, h: number){
    // if(this.matterBall.bodies[4].position.x<-100){
    //   this.matterBall.bodies.map((body)=>{
    //     Matter.Body.setPosition(body, Matter.Vector.create(body.position.x+w+100, body.position.y));
    //     Matter.Body.setVelocity(body, Matter.Vector.create(0, 0));
    //   })

    // }
    const from = Matter.Vector.create(this.matterBall.bodies[4].position.x, this.matterBall.bodies[4].position.y);
    const fx = 2500;
    const force = Matter.Vector.create(Math.random() / 2500 - 0.0002, Math.random() / fx - 1/fx/2)
    for (const body of this.matterBall.bodies) {
      Matter.Body.applyForce(body, from, force);
    }
  }

  private onClick(){
    router.push(this.path);
  }

  private getRandomInt(max: number) {
    return Math.floor(Math.random() * Math.floor(max));
  }
}



















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/app';
@Component
export default class HeaderComponent extends Vue {
  private get black(){
    return this.$route.path == '/';
  }
  private isActive = false;
  @Watch('$route')
  private routeChanged(){
    this.isActive = false;
  }
}


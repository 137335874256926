import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    // ホーム
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    // 固定ページ
    path: '/contents/:slug',
    name: 'Content',
    component: () => import('../views/Page.vue')
  },
  {
    //作品一覧
    path: '/projects/',
    name: 'ProjectsList',
    component: () => import('../views/ProjectsList.vue')
  },
  {
    //テーマで絞り込まれた作品一覧
    path: '/themes/:main_theme/:page?',
    name: 'MainTheme',
    component: () => import('../views/ProjectsView.vue')
  },
    {
    //タグで絞り込まれた作品一覧
    path: '/tags/:project_tag/:page?',
    name: 'ProjectTag',
    component: () => import('../views/ProjectsView.vue')
  },
  {
    //研究室タグで絞り込まれた作品一覧
    path: '/lab/:lab/:page?',
    name: 'Lab',
    component: () => import('../views/ProjectsView.vue')
  },
  {
    //学科タグで絞り込まれた作品一覧
    path: '/department/:department/:page?',
    name: 'Department',
    component: () => import('../views/ProjectsView.vue')
  },
  {
    //部門タグで絞り込まれた作品一覧
    path: '/section/:section/:page?',
    name: 'Section',
    component: () => import('../views/ProjectsView.vue')
  },
  {
    //キーワード検索結果一覧
    path: '/search/:keyword/:page?',
    name: 'Search', 
    component: () => import('../views/ProjectsView.vue')
  },
  {
    //作品詳細
    path: '/project/:id',
    name: 'Project', 
    component: () => import('../views/ProjectView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
